import React, { useEffect } from 'react'
import PortfolioCom from '../components/Home/PortfolioCom'
import Layout from '../components/Layout'
import Hero from '../components/Mobileapp/hero'
import Seo from '../components/Seo'
import allApiData from "../api";
import SeoheadData from '../siteScriptData'



const Technologies = () => {
    const tabName = ['Frontend', 'Backend', 'Database', 'Infrastructure'];
    // const tabName = ['Design', 'Frontend', 'Backend', 'Database', 'Infrastructure'];
    useEffect(() => {
        allApiData.aosinit()
    }, []);
    return (
        <Layout>
            <Seo SeoData={SeoheadData.technologiesPageSeoData}></Seo>
            <section className='technologies'>
                <div className="technologies">
                    <Hero cta="Get a Quote" title="Technologies We Deploy" def="Our cutting-edge technologies help in delivering our customers with software solutions that are seamless, efficient, and streamlines with their vision.">
                        <div className="hero-img hide-animation-768">
                            <img src="../assets/img/technologies/hero_img/techonologies-we-deploy.svg"
                                className=" z-index img-fluid main-img-only wh-auto" alt="techonologies-we-deploy" width="650" height="676" />
                            <img src="../assets/img/technologies/hero_img/1.svg"
                                className="  img-fluid t-one " alt="techonologies-we-deploy-one" />
                            <img src="../assets/img/technologies/hero_img/2.svg"
                                className="  img-fluid t-two " alt="techonologies-we-deploy-two" />
                        </div>
                        <div className="show-main-img-768">
                            <img src="../assets/img/technologies/hero_img/techonologies-we-deploy-mobile.svg" className="wh-auto img-fluid" alt="techonologies-we-deploy-mobile"
                                width="650" height="676"
                            />
                        </div>
                    </Hero>
                </div>

                <div className='mobile-app-technologies margin-2 pt-lg-5 pb-lg-4'>
                    <PortfolioCom isViewBtn={false} heading="Technologies Used for Mobile App Development"
                        subTitle=" Mobile app development requires implementation of latest technologies, tools and applications that streamline the prototyping, development, designing and testing processes."
                        isHeading={true}
                        isTabOn={true}
                        learnbutton={false}
                        tabData={tabName}
                        techType="app"
                        activeTab="Backend"
                    />
                </div>

                <div className='mobile-game-technologies margin-2 '>
                    <PortfolioCom isViewBtn={false} heading="Technologies Used for Game Development"
                        subTitle="Game development includes character designing, animation, navigation building and functional testing. Each step needs efficient technologies and applications for smoother output."
                        isHeading={true}
                        isTabOn={true}
                        learnbutton={false}
                        tabData={tabName}
                        techType="game"
                        activeTab="Frontend"
                    />
                </div>

                <div className='web-development-technologies pb-50 margin-2 '>
                    <div className="pt-lg-5 pb-lg-4">
                        <PortfolioCom isViewBtn={false}
                            heading="Technologies Used for Web Development"
                            subTitle="Web development may look simple is one of the most challenging and complex operations because it deals with user experience. The technologies used for web development are agile and efficient."
                            isHeading={true}
                            isTabOn={true}
                            learnbutton={false}
                            tabData={tabName}
                            techType="web"
                            activeTab="Database"
                            isLimitPortF={7}
                        />
                    </div>
                </div>

            </section >
        </Layout >
    )
}

export default Technologies